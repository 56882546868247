import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import Tilt from "react-tilt";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import illustration from "../../../assets/img/home-sec-3-2.jpg";
import line1 from "../../../assets/img/lines/03.png";
import line2 from "../../../assets/img/lines/04.png";
import Datastore from "../../../data/DataStore";

const WhyUsComponent = () => {
  const whyUsObj = {
    sectionOne: {
      title: "Environmental<br />Friendly<br />Technology",
      features: [
        {
          title: "Hydro-Phobic",
          icon: "water-repellent.png",
        },
        {
          title: "UV Resistant",
          icon: "uv-protection.png",
        },
        {
          title: "Environment Friendly",
          icon: "eco.png",
        },
        {
          title: "Anti-Fungal/Bacterial/Algae",
          icon: "antivirus.png",
        },
        {
          title: "Anti-Corrosive",
          icon: "anticorrosive.png",
        },
        {
          title: "Low VOC",
          icon: "voc.png",
        },
        {
          title: "Elastomeric",
          icon: "elasticity.png",
        },
      ],
    },
    sectionTwo: {
      title: "The New<br />Lifestyle<br />Choice",
      features: [
        {
          title: "Simple Application",
          icon: "tap.png",
        },
        {
          title: "Hygienic",
          icon: "safe.png",
        },
        {
          title: "Impact Resistant",
          icon: "impact-resistant.png",
        },
        {
          title: "Quick Drying",
          icon: "fast-time.png",
        },
        {
          title: "Molecular Bonding",
          icon: "molecules.png",
        },
        {
          title: "Scratch Resistant",
          icon: "scratch.png",
        },
      ],
    },
  };

  const whyuspopover = (obj) => {
    return (
      <Popover>
        <Popover.Content>
          <img src={Datastore.Images[obj]} alt="" className="featureImage" />
        </Popover.Content>
      </Popover>
    );
  };

  useEffect(() => {
    document.querySelector(
      "#center"
    ).innerHTML = `<img src="${Datastore.Images.iCanNano}" alt="" class="img-fluid">`;
  }, []);

  return (
    <section className="wcu-section box-style mt-5">
      <div className="container">
        <div className="wcu-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div
                className="wcu-image text-center text-lg-left wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <Tilt options={{ max: 25 }}>
                  <img src={illustration} alt="" />
                </Tilt>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="wcu-text">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">Why choose I-Can Nano</span>
                </div>
                <p>
                  Introducing the most comprehensive range of coatings & paints
                </p>
                <div className="row">
                  <div className="col-md-6 col-xs-12 mt-3">
                    <h5
                      style={{ color: "white", fontSize: "16px" }}
                      className="whyustitle text-center caps"
                      dangerouslySetInnerHTML={{
                        __html: whyUsObj.sectionOne.title,
                      }}
                    />
                    <ul className="wcu-list" style={{ fontSize: "12px" }}>
                      {whyUsObj.sectionOne.features.map((item, key) => (
                        <li className="homeFeatureLi" key={key}>
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={whyuspopover(item.icon)}
                          >
                            <span>{item.title}</span>
                          </OverlayTrigger>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-6 col-xs-12 mt-3">
                    <h5
                      style={{ color: "white", fontSize: "16px" }}
                      className="whyustitle text-center caps"
                      dangerouslySetInnerHTML={{
                        __html: whyUsObj.sectionTwo.title,
                      }}
                    />
                    <ul className="wcu-list" style={{ fontSize: "12px" }}>
                      {whyUsObj.sectionTwo.features.map((item, key) => (
                        <li className="homeFeatureLi" key={key}>
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={whyuspopover(item.icon)}
                          >
                            <span>{item.title}</span>
                          </OverlayTrigger>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={line1} alt="shape" className="line-shape-one" />
          <img src={line2} alt="shape" className="line-shape-two" />
        </div>
      </div>
    </section>
  );
};

export default WhyUsComponent;
