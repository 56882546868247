import React from "react";
import { Link } from "react-router-dom";

const banImages = {
  "bg1.jpg": require("../../assets/img/banner/bg1.jpg"),
  "bg2.jpg": require("../../assets/img/banner/bg2.jpg"),
  "bg3.jpg": require("../../assets/img/banner/bg3.jpg"),
  "bg4.jpg": require("../../assets/img/banner/bg4.jpg"),
  "bg5.jpg": require("../../assets/img/banner/bg5.jpg"),
  "bg6.jpg": require("../../assets/img/banner/bg6.jpg"),
  "bg7.jpg": require("../../assets/img/banner/bg7.jpg"),
  "bg8.jpg": require("../../assets/img/banner/bg8.jpg"),
  "bg9.jpg": require("../../assets/img/banner/bg9.jpg"),
  "bg10.jpg": require("../../assets/img/banner/bg10.jpg"),
  "bg11.jpg": require("../../assets/img/banner/bg11.jpg"),
  "bg12.jpg": require("../../assets/img/banner/bg12.jpg"),
  "bg13.jpg": require("../../assets/img/banner/bg13.jpg"),
  "bg14.jpg": require("../../assets/img/banner/bg14.jpg"),
  "bg15.jpg": require("../../assets/img/banner/bg15.jpg"),
  "bg16.jpg": require("../../assets/img/banner/bg16.jpg"),
  "contact.jpg": require("../../assets/img/banner/contact.jpg"),
};

export default ({ breadcrumb }) => {
  return (
    <section
      className="breadcrumb-section bg-img-c"
      style={{
        backgroundImage: "url(" + banImages[breadcrumb.breadcrumbimg] + ")",
      }}
    >
      <div className="container">
        <div className="breadcrumb-text">
          <h1 className="page-title">{breadcrumb.title}</h1>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">{breadcrumb.type}</Link>
            </li>
            <li>{breadcrumb.title}</li>
          </ul>
        </div>
      </div>
      <div className="breadcrumb-shapes">
        <div className="one" />
        <div className="two" />
        <div className="three" />
      </div>
    </section>
  );
};
