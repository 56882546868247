import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../../../assets/img/slider/trans.png";
import iCanNano from "../../../assets/img/logo/iCanNano.png";

const bannerpost = [
  {
    img: img1,
    tag: "Nanotechnology By",
    title: `<img src=${iCanNano} alt="" class="SliderLogo">`,
    btntext1: "",
  },
];

export default () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    fade: false,
    dots: false,
    swipe: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="banner-section">
      <video
        src="/assets/img/video-bg/nano.webm"
        className="bannervid"
        type="video/webm"
        autoPlay
        loop
        muted
      ></video>
      <Slider className="banner-slider" id="bannerSlider" {...settings}>
        {bannerpost.map((item, i) => (
          <div key={i}>
            <div
              className="single-banner"
              style={{ backgroundImage: "url(" + item.img + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-10">
                    <div className="banner-content">
                      <span
                        className="promo-text"
                        data-animation="fadeInDown"
                        data-delay="0.8s"
                      >
                        {item.tag}
                      </span>
                      {/* <h1 data-animation="fadeInUp" data-delay="1s">
                        {item.title}
                      </h1> */}
                      <div
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></div>
                      <ul className="btn-wrap">
                        <li data-animation="fadeInRight" data-delay="1.4s">
                          <Link to="/products" className="main-btn main-btn-2">
                            {item.btntext1}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-shapes">
                <div className="one" />
                <div className="two" />
                <div className="three" />
                <div className="four" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};
