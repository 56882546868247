const images = {
  "Anti Corrosive": require("../assets/img/icons/anticorrosive.png"),
  "Anti Bacterial": require("../assets/img/icons/antivirus.png"),
  "Dust Repellent": require("../assets/img/icons/dust-repellant.png"),
  "Eco Friendly": require("../assets/img/icons/eco.png"),
  Stretchable: require("../assets/img/icons/elasticity.png"),
  "Quick Dry": require("../assets/img/icons/fast-time.png"),
  "Impact Resistant": require("../assets/img/icons/impact-resistant.png"),
  "Molecular Bonding": require("../assets/img/icons/molecules.png"),
  "Zero Chemical Hazard": require("../assets/img/icons/no-chemical.png"),
  "Rain Repellent": require("../assets/img/icons/rain-repellent.png"),
  Hygienic: require("../assets/img/icons/safe.png"),
  "tap.png": require("../assets/img/icons/tap.png"),
  "UV Protection": require("../assets/img/icons/uv-protection.png"),
  "Water Repellent": require("../assets/img/icons/water-repellent.png"),
  "zero-chemicals.png": require("../assets/img/icons/zero-chemicals.png"),
  "voc.png": require("../assets/img/icons/voc.png"),
  "scratch.png": require("../assets/img/icons/scratch.png"),
  "bg3.jpg": require("../assets/img/banner/bg3.jpg"),
  "project-01.jpg": require("../assets/img/details/project-01.jpg"),
  iCanNano: require("../assets/img/logo/iCanNano.png"),
};

export default images;
