import React from "react";

import thumb from "../../../assets/img/about/nanotech.jpg";

export default () => {
  return (
    <section className="project-details section-gap">
      <div className="container">
        <div className="project-thumb">
          <img src={thumb} alt="" />
        </div>
        <div className="project-content mt-60">
          <div className="row">
            <div className="col-lg-8 order-2 order-lg-2">
              <div className="content">
                <h4>
                  I-CanNano (<span className="textRed">I</span>nnovative{" "}
                  <span className="textRed">C</span>entre for{" "}
                  <span className="textRed">A</span>pplied{" "}
                  <span className="textRed">N</span>ano Technology)
                </h4>
                <p className="mb-40">
                  <span className="textRed">I-CanNano</span> is the brain child
                  of <strong className="textRed">Dr. Arup Chatterjee</strong>{" "}
                  who has been inspired & mentored on this project by{" "}
                  <strong className="textRed">(Lt) Dr. APJ Abdul Kalam</strong>.
                  Dr. Arup Chatterjee, M.Sc, M.Tech & Ph.D, is highly talented
                  scientist with applied technological bent in mind. He is
                  committed towards the application of Nanotechnology for the
                  common man and achieving it through innovation. The vision of
                  Dr. Kalam “Affordable nanotechnology for common man” is well
                  adopted to achieve the goals of the organization. Dr. Arup
                  Chatterjee’s diverse interest in wide application has led to
                  the development of many novel and high performance products.
                  With strong scientific capability & determination, he has
                  crossed many barriers of technology development in his decades
                  of long research on Nanotechnology to arrive at successful
                  products and solutions.
                </p>
                <h5>What is Nano Technology?</h5>
                <p>
                  Nanotechnology is the precise and controlled fabrication or
                  assembly of atoms and molecules at nanometer (10<sup>-9</sup>{" "}
                  m) dimensions into novel materials and devices with unique
                  properties. 1 nanometer equals 1/1000th of the human hair. For
                  example, nano particle in comparison to a football is
                  equivalent to football in comparison to earth. Nanotechnology
                  is being hugely accepted to be the revolution where every
                  aspect of human life will be hugely impacted. There is a lot
                  of scope for improvement of the standards of existing products
                  by application of nanotechnology along with addition of
                  multiple features leading to the creation of unique and
                  sustainable products.
                </p>
                <img
                  src={require("../../../assets/img/about/picture1.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <div className="details">
                <ul>
                  <h5 style={{ color: "white", marginBottom: "25px" }}>
                    Environment Friendly Technology
                  </h5>
                  <li>
                    <p>Hydro-phobic</p>
                    <p>UV Resistant</p>
                    <p>Anti-Corrosive</p>
                    <p>Low VOC</p>
                    <p>Elastomeric</p>
                    <p>Anti-Fungal/Bacterial/Algae</p>
                    <p>Eco Friendly</p>
                  </li>
                </ul>
              </div>
              <div className="details" style={{ marginTop: "30px" }}>
                <ul>
                  <h5 style={{ color: "white", marginBottom: "25px" }}>
                    The New Lifestyle Choice
                  </h5>
                  <li>
                    <p>Simple Application</p>
                    <p>Hygienic</p>
                    <p>Impact Resistant</p>
                    <p>Quick Drying</p>
                    <p>Molecular Bonding</p>
                    <p>Scratch Resistant</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 order-1">
              <div className="content">
                <p>
                  Conventional paints or coatings consist of micron size
                  particles (pigments) in an organic matrix. These micron size
                  particles have defects in its structure and defects increases
                  as the particle size grows in size. The gap between micron
                  size particles is high hence the performance of the paint
                  which is a combination of organic matrix & inorganic particles
                  is not perfect. Thus paint is susceptible to ageing and
                  defects hence inadequate performance is witnessed.
                </p>

                <p>
                  In nanotechnology paint system, gap between nano-size
                  particles are minimal due to the self-assembly feature
                  incorporated. Inorganic nano-materials are structurally
                  defect-free and this leads to robustness in performance. In
                  nanotech paints, novel features are incorporated in
                  nano-materials & dispersed in paint matrix. Thus
                  nanotechnology paint system has superior performance as well
                  as novel characteristics that are not available in
                  conventional paint system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
