import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Category from "./ProductCategory";
import Detail from "./ProductDetail";

const ProductsComponent = () => {
  const [type, setType] = useState("");

  const catId = new URLSearchParams(window.location.search).get("categoryId");
  const prodId = new URLSearchParams(window.location.search).get("productId");

  useEffect(() => {
    if (catId) {
      setType("Category");
    } else if (prodId) {
      setType("Detail");
    }
  }, [catId, prodId]);

  const renderComponent = () => {
    switch (type) {
      case "Category":
        return <Category />;
      case "Detail":
        return <Detail />;
      default:
        return;
    }
  };

  return (
    <>
      <MetaTags>
        <title>Products - {type} | Super Marketing</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      {renderComponent()}
      <Footer />
    </>
  );
};

export default ProductsComponent;
