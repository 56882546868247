import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import Images from "../../../data/Images";
import { firestore } from "../../Firebase";

export default ({ data, prodId, cId }) => {
  const [category, setCategory] = useState([]);
  const [showApplication, setShowApplication] = useState(true);
  const [showTechData, setShowTechData] = useState(true);

  useEffect(() => {
    if (data.application) {
      setShowApplication(false);
    }
    if (data.technical) {
      setShowTechData(false);
    }
    if (cId) {
      firestore
        .collection("categories")
        .doc(cId)
        .get()
        .then((res) => {
          setCategory(res.data());
        });
    }
  }, [data, cId]);

  return (
    <section className="shop-details-wrap">
      <div className="container">
        <div className="product-details section-gap">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div
                className="details"
                style={{ backgroundColor: "whitesmoke", padding: "10px" }}
              >
                <ul>
                  {data.features
                    ? data.features.map((feature, key) => (
                        <li
                          style={{
                            borderBottom: "1px solid #CCC",
                            padding: "5px",
                          }}
                          key={key}
                        >
                          <div className="row">
                            <div className="col-3">
                              <img src={Images[feature]} alt="" />
                            </div>
                            <div
                              className="col-9"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "18px",
                                  fontFamily: "Alegreya Sans SC",
                                }}
                              >
                                {feature}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="product-summary">
                <h3 className="product-name" style={{ color: "#666" }}>
                  {data.title}
                </h3>
                <small>
                  in Category{" "}
                  <a
                    href={
                      "/products/" +
                      category.category +
                      "?categoryId=" +
                      category._id
                    }
                  >
                    {category.category}
                  </a>
                </small>
                <div className="short-description">
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="product-details-tab">
                <Tab.Container defaultActiveKey="benefits">
                  <div
                    className="tab-filter-nav"
                    style={{ borderBottom: "1px solid #ccc" }}
                  >
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="benefits">Benefits</Nav.Link>
                      </Nav.Item>
                      <Nav.Item hidden={showApplication}>
                        <Nav.Link eventKey="application">
                          Application Instructions
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item hidden={showTechData}>
                        <Nav.Link eventKey="technical">Technical Data</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="benefits">
                      <div className="product-description">
                        <div
                          dangerouslySetInnerHTML={{ __html: data.benefits }}
                        ></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="application" hidden={showApplication}>
                      <div className="product-description">
                        <div
                          dangerouslySetInnerHTML={{ __html: data.application }}
                        ></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="technical" hidden={showTechData}>
                      <div className="product-description">
                        <div
                          dangerouslySetInnerHTML={{ __html: data.technical }}
                        ></div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
