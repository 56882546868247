import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";

var firebaseConfig = {
  apiKey: "AIzaSyBF8qPwVn6S9rXHcSq1pT4pKS6iuv2eoHQ",
  authDomain: "super-marketing-demo-site.firebaseapp.com",
  projectId: "super-marketing-demo-site",
  storageBucket: "super-marketing-demo-site.appspot.com",
  messagingSenderId: "730392128899",
  appId: "1:730392128899:web:7d2e3f1076be2791445ab6",
  measurementId: "G-3SCRX9CKPM",
};
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();

export { firestore, auth, storage, database };
