import React from "react";
import { Link } from "react-router-dom";

const ProductItem = ({ item }) => {
  const image = require(`../../../assets/img/product/${item.image}`);
  const catId = new URLSearchParams(window.location.search).get("categoryId");
  const link = `/products/product=${item.title
    .toLowerCase()
    .replace(/ /g, "-")}?productId=${item.id}&cId=${catId}`;

  return (
    <>
      <div className="col-lg-4 col-md-6 col-10 col-tiny-12">
        <div className="feature-box">
          <div
            className="feature-bg bg-img-c"
            style={{
              backgroundImage: "url(" + image + ")",
            }}
          ></div>
          <div className="feature-desc">
            <Link to={link} className="feature-link">
              <i className="fal fa-long-arrow-right" />
            </Link>
            <br />
            <Link to={link} className="feature-link">
              <h4>{item.title}</h4>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductItem;
