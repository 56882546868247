import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Recaptcha from "react-recaptcha";
import illustration from "../../../assets/img/contact.jpg";

export default () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [message, setMessage] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const recaptchaLoaded = () => {
    console.log("Recaptcha Loaded");
  };

  const verifyCallback = (res) => {
    if (res) {
      setIsVerified(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isVerified) {
      emailjs
        .sendForm(
          "superm_web_email_service",
          "superm_web_contact",
          e.target,
          "user_zhHBswp6Nr8eZI0CtWzrn"
        )
        .then(
          (result) => {
            Swal.fire({
              icon: "success",
              title: "Done",
              text: "Your message was sent!",
            });
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong! Please Try again",
            });
          }
        )
        .then(() => {
          window.location.reload();
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please verify that you are human!",
      });
    }
  };

  return (
    <section className="contact-section contact-page section-gap-top">
      <div className="container">
        <div className="contact-info">
          <div className="row justify-content-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="illustration-img text-center">
                <img src={illustration} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-10 order-1 order-lg-2">
              <div className="contact-info-content">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">Can We Help You?</span>
                </div>
                <ul className="contactDetails">
                  <li>
                    <i className="far fa-phone" />
                    <a href="tel:+918748889999">+ 91 8748889999</a>
                  </li>
                  <li>
                    <i className="far fa-envelope-open" />
                    <a href="mailto:info@supermarketing.co.in">
                      info@supermarketing.co.in
                    </a>
                  </li>
                  <li>
                    <i className="far fa-map-marker-alt" />
                    0/12, D Block, Platinum City, Yeshwantpur, Bangalore 560022
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form grey-bg">
          <div className="row no-gutters justify-content-center">
            <div className="col-10">
              <div className="section-title text-center mb-40">
                <h2 className="title">Drop us a Mail</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="text"
                        placeholder="Your Full Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                        id="name"
                      />
                      <span className="icon">
                        <i className="far fa-user-circle" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="email"
                        placeholder="Your Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        id="email"
                      />
                      <span className="icon">
                        <i className="far fa-envelope-open" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="text"
                        placeholder="Your Phone"
                        value={phonenumber}
                        onChange={(e) => setPhonenumber(e.target.value)}
                        name="phonenumber"
                        id="phonenumber"
                      />
                      <span className="icon">
                        <i className="far fa-phone" />
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group textarea mb-30">
                      <textarea
                        placeholder="Write Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        name="message"
                        id="message"
                      />
                      <span className="icon">
                        <i className="far fa-pencil" />
                      </span>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="main-btn"
                      hidden={!isVerified}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row justify-content-around mt-4">
            <Recaptcha
              sitekey="6LcSx-MaAAAAABumlFnYYtEmk0Z0976Wj1EHhmkM"
              render="explicit"
              onloadCallback={recaptchaLoaded}
              verifyCallback={verifyCallback}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid container-1600">
        <div className="contact-map" style={{ marginTop: "200px" }}>
          <iframe
            title="map"
            src="https://snazzymaps.com/embed/314698"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex={0}
          />
        </div>
      </div>
    </section>
  );
};
