import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
// Preloader
import Preloader from "./components/layouts/Preloader";
// Pages
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Products from "./components/pages/Products";
import About from "./components/pages/About";
import NanoTechnology from "./components/pages/NanoTechnology";

export default () => {
  return (
    <Router>
      <Preloader />
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/contact" component={Contact} />
          <Route path="/products" component={Products} />
          <Route path="/about" component={About} />
          <Route path="/nanotechnology" component={NanoTechnology} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};
