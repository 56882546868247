import React from "react";
import { Link } from "react-router-dom";

// import illustration from "../../../assets/img/home-sec-2-1.jpg";

export default () => {
  return (
    <section className="about-section about-illustration-img ">
      <div className="container">
        <div className="about-text">
          <div className="section-title left-border">
            <span className="title-tag">What is Nano Technology?</span>
          </div>
          <p style={{ marginBottom: "20px" }}>
            Nanotechnology is the precise and controlled fabrication or assembly
            of atoms and molecules at nanometer (10<sup>-9</sup> m) dimensions
            into novel materials and devices with unique properties.....
          </p>
          <Link
            to="/nanotechnology"
            className="main-btn"
            style={{ float: "right" }}
          >
            Learn More
          </Link>
          <div className="section-title left-border mb-20">
            <span className="title-tag">
              I-CanNano (I<span style={{ color: "black" }}>nnovation</span> C
              <span style={{ color: "black" }}>enter</span>{" "}
              <span style={{ color: "black" }}>of</span> A
              <span style={{ color: "black" }}>pplied</span> N
              <span style={{ color: "black" }}>anotechnology</span> )
            </span>
          </div>
          <div className="col-md-6">
            <p style={{ marginBottom: "20px" }}>
              We want to be the most customer-centric & agile company which
              introduces innovative products & solutions and creates a
              difference in people’s life.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
