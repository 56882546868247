import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/home/Content";

const HomePage = (props) => {
  return (
    <Fragment>
      <MetaTags>
        <title>Super Marketing | Homepage</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      <Content history={props.history} />
      <Footer />
    </Fragment>
  );
};

export default HomePage;
