import React from "react";
import { Link } from "react-router-dom";

import shape1 from "../../assets/img/lines/01.png";
import shape2 from "../../assets/img/lines/02.png";

export default () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer>
      <div className="container">
        <div className="footer-widget">
          <div className="row">
            <div className="col-lg-4 col-sm-5 order-1">
              <div className="widget site-info-widget">
                <ul className="social-links">
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-behance" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-dribbble" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 col-sm-7 order-2">
              <div className="widget newsletter-widget"></div>
            </div>
            <div className="col-lg-3 col-sm-6 order-3">
              <div className="row">
                <div className="widget contact-widget">
                  <h4 className="widget-title">Phone</h4>
                  <ul className="contact-infos">
                    <li>
                      <Link to="tel:+0123456789">
                        <i className="far fa-phone" />+ 91 8748889999
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 order-3">
              <div className="row">
                <div className="widget contact-widget">
                  <h4 className="widget-title">Email</h4>
                  <ul className="contact-infos">
                    <li>
                      <Link to="mailto:info@supermarketing.co.in">
                        <i className="far fa-envelope-open" />
                        info@supermarketing.co.in
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 order-4">
              <div className="row">
                <div className="widget contact-widget">
                  <h4 className="widget-title">Write to Us</h4>
                  <ul className="contact-infos">
                    <li>
                      {" "}
                      <i className="far fa-map-marker-alt" /> 0/12, D Block,
                      Platinum City, Yeshwantpur, Bangalore 560022
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p className="copyright-text">
            <span>
              Copyright @ <Link to="#">Super Marketing</Link> 2021
            </span>
            <span>
              Developed by <a href="https://www.naveenr.com">Naveen R</a>
            </span>
          </p>
          <Link to="#" className="back-to-top" onClick={scrollToTop}>
            <i className="far fa-angle-up" />
          </Link>
        </div>
      </div>
      {/* Lines */}
      <img src={shape1} alt="line" className="line-one" />
      <img src={shape2} alt="line" className="line-two" />
    </footer>
  );
};
