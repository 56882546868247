import React, { useState, useEffect } from "react";
import PieMenu, { Slice } from "react-pie-menu";

const windowWidth = window.innerWidth;

const AboutPieMenu = ({ history, data }) => {
  const [pieImage, setPieImage] = useState(data.PieMenu[0].image);
  const [pieImgHeight, setPieImgHeight] = useState(169);
  const [pieImgWidth, setPieImgWidth] = useState(169);
  const [isMobile, setIsMobile] = useState(false);
  const [pieMenuTitle, setPieMenuTitle] = useState(data.PieMenu[0].title);

  const piemenuMouseOverAction = (e, pie) => {
    e.preventDefault();
    setPieImage(pie.image);
    setPieMenuTitle(pie.title);
  };

  useEffect(() => {
    const elem = document.querySelector(".pieImgWrap");
    setPieImgHeight(elem.offsetHeight / 2);
    setPieImgWidth(elem.offsetWidth);
    if (windowWidth < 767) {
      setIsMobile(true);
    }
  }, []);

  return (
    <section className="about-section about-illustration-img" hidden={isMobile}>
      <div className="container">
        <div className="about-text">
          <div className="section-title left-border">
            <span className="title-tag">Featured Products</span>
          </div>
          <div className="row">
            <div className="col-12 col-md-5">
              <PieMenu radius={`${pieImgHeight}px`} centerRadius="50px">
                {data.PieMenu.map((pie, key) => (
                  <Slice
                    backgroundColor={pie.bgColor}
                    color={pie.textColor}
                    key={key}
                    onMouseOver={(e) => piemenuMouseOverAction(e, pie)}
                    onSelect={() => history.push(pie.link)}
                  >
                    <span
                      style={{ fontSize: "12px" }}
                      dangerouslySetInnerHTML={{ __html: pie.title }}
                    />
                  </Slice>
                ))}
              </PieMenu>
            </div>
            <div className="col-12 col-md-7 pieImgWrap">
              <img
                src={require(`../../../assets/img/piemenu/` + pieImage)}
                alt=""
                className="pieImg"
              />
              <div
                className="pieMenuContent"
                style={{ width: pieImgWidth - 30 }}
                dangerouslySetInnerHTML={{
                  __html: pieMenuTitle.replace("<br />", " "),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPieMenu;
