const DataStore = {
  PieMenu: [
    {
      id: 1,
      title: "Hygiene<br />Coating",
      image: "7-hycoat.jpg",
      text: "Lorem ipsum 7, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#ed1c24",
      textColor: "#ffffff",
      link: "/products/product=nano-hygiene-coating?productId=0Nv93pGDbqDMwRGu0P7V&cId=jj1kuWss75WUqmdpp2Aq",
    },
    {
      id: 2,
      title: "Wood<br />Coating",
      image: "9-ancoat.jpg",
      text: "Lorem ipsum 9, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#d32f2f",
      textColor: "#ffffff",
      link: "/products/product=nano-wood-coating?productId=QPV2dkokEYSsay2hc6XF&cId=jj1kuWss75WUqmdpp2Aq",
    },
    {
      id: 3,
      title: "Leather<br />Coating",
      image: "4-lcoat.jpg",
      text: "Lorem ipsum 4, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#b71c1c",
      textColor: "#ffffff",
      link: "/products/product=nano-leather-coating?productId=YduxbzmzzRmOhwXGeHOe&cId=jj1kuWss75WUqmdpp2Aq",
    },
    {
      id: 4,
      title: "Ceramic<br />Coating",
      image: "2-cercoat.jpg",
      text: "Lorem ipsum 2, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#ed1c24",
      textColor: "#ffffff",
      link: "/products/product=nano-ceramic-coating?productId=fJn9xEb50YHyj7tbI0Wi&cId=jj1kuWss75WUqmdpp2Aq",
    },
    {
      id: 5,
      title: "Stone<br />Coating",
      image: "5-scoat.jpg",
      text: "Lorem ipsum 5, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#d32f2f",
      textColor: "#ffffff",
      link: "/products/product=nano-stone-coating?productId=ToWX2LJctjZxw4vzwk4S&cId=mYVgGJwxzDI6AUhAhMyD",
    },
    {
      id: 6,
      title: "Glass<br />Coating",
      image: "6-glcoat.jpg",
      text: "Lorem ipsum 6, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#b71c1c",
      textColor: "#ffffff",
      link: "/products/product=nano-glass-coating?productId=mVYVbob41r8S8JyUN4D4&cId=NtoxgnaIlmeKr8DK9s9D",
    },
    {
      id: 7,
      title: "Wall<br />Putty",
      image: "1-wp.jpg",
      text: "Lorem ipsum 1, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#ed1c24",
      textColor: "#ffffff",
      link: "/products/product=nano-wall-putty?productId=M4RGNPfgFxY35gv9eMVx&cId=iGMV9uE1tdTA2GjmJaiW",
    },
    {
      id: 8,
      title: "Acrylic<br />Wall Putty",
      image: "3-acrwp.jpg",
      text: "Lorem ipsum 3, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#d32f2f",
      textColor: "#ffffff",
      link: "/products/product=nano-acrylic-wall-putty?productId=oUh0FyY4Psf23tSVGhwn&cId=iGMV9uE1tdTA2GjmJaiW",
    },

    {
      id: 9,
      title: "Quartz<br />Wall Putty",
      image: "10-qtzcoat.jpg",
      text: "Lorem ipsum 8, dolor sit amet consectetur adipisicing elit. Modi iure nobis, amet necessitatibus aut animi cumque provident. Omnis culpa enim quibusdam accusantium laboriosam magni iusto, mollitia nostrum amet, laborum quasi.",
      bgColor: "#b71c1c",
      textColor: "#ffffff",
      link: "/products/product=nano-quartz-putty?productId=uDqKRt0Px8TnRXJU8Qsp&cId=iGMV9uE1tdTA2GjmJaiW",
    },
  ],
  Images: {
    "anticorrosive.png": require("../assets/img/icons/anticorrosive.png"),
    "antivirus.png": require("../assets/img/icons/antivirus.png"),
    "dust-repellant.png": require("../assets/img/icons/dust-repellant.png"),
    "eco.png": require("../assets/img/icons/eco.png"),
    "elasticity.png": require("../assets/img/icons/elasticity.png"),
    "fast-time.png": require("../assets/img/icons/fast-time.png"),
    "impact-resistant.png": require("../assets/img/icons/impact-resistant.png"),
    "molecules.png": require("../assets/img/icons/molecules.png"),
    "no-chemical.png": require("../assets/img/icons/no-chemical.png"),
    "rain-repellent.png": require("../assets/img/icons/rain-repellent.png"),
    "safe.png": require("../assets/img/icons/safe.png"),
    "stretch.png": require("../assets/img/icons/stretch.png"),
    "sun-protection.png": require("../assets/img/icons/sun-protection.png"),
    "tap.png": require("../assets/img/icons/tap.png"),
    "uv-protection.png": require("../assets/img/icons/uv-protection.png"),
    "water-repellent.png": require("../assets/img/icons/water-repellent.png"),
    "zero-chemicals.png": require("../assets/img/icons/zero-chemicals.png"),
    "voc.png": require("../assets/img/icons/voc.png"),
    "scratch.png": require("../assets/img/icons/scratch.png"),
    "bg3.jpg": require("../assets/img/banner/bg3.jpg"),
    "project-01.jpg": require("../assets/img/details/project-01.jpg"),
    iCanNano: require("../assets/img/logo/iCanNano.png"),
  },
};

export default DataStore;
