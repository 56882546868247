import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import thumb from "../../../assets/img/about/mainbanner.jpg";
import Datastore from "../../../data/DataStore";

export default () => {
  const whyUsObj = {
    sectionOne: {
      title: "Environmental Friendly Technology",
      features: [
        {
          title: "Hydro-Phobic",
          icon: "water-repellent.png",
        },
        {
          title: "UV Resistant",
          icon: "uv-protection.png",
        },
        {
          title: "Environment Friendly",
          icon: "eco.png",
        },
        {
          title: "Anti-Fungal/Bacterial/Algae",
          icon: "antivirus.png",
        },
        {
          title: "Anti-Corrosive",
          icon: "anticorrosive.png",
        },
        {
          title: "Low VOC",
          icon: "voc.png",
        },
        {
          title: "Elastomeric",
          icon: "elasticity.png",
        },
      ],
    },
    sectionTwo: {
      title: "The New Lifestyle Choice",
      features: [
        {
          title: "Simple Application",
          icon: "tap.png",
        },
        {
          title: "Hygienic",
          icon: "safe.png",
        },
        {
          title: "Impact Resistant",
          icon: "impact-resistant.png",
        },
        {
          title: "Quick Drying",
          icon: "fast-time.png",
        },
        {
          title: "Molecular Bonding",
          icon: "molecules.png",
        },
        {
          title: "Scratch Resistant",
          icon: "scratch.png",
        },
      ],
    },
  };

  const itemPopover = (obj) => {
    return (
      <Popover>
        <Popover.Content>
          <img src={Datastore.Images[obj]} alt="" className="featureImage" />
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <section className="project-details section-gap">
      <div className="container">
        <div className="project-thumb">
          <img src={thumb} alt="" />
        </div>
        <div className="project-content mt-60">
          <div className="row">
            <div className="col-lg-8 order-2 order-lg-2">
              <div className="content">
                <h2>About Us</h2>
                <p className="mb-40">
                  <p>
                    Team of enthusiastic professionals with great team spirit –
                    That’s WE at Super Marketing. Our team from diverse proven
                    background have come together to take Nano Technology to
                    greater heights.
                  </p>

                  <p>
                    It is our pride that I-CanNano, pioneers in Nano technology,
                    has collaborated with Super Marketing for their range of
                    outstanding products. Super Marketing will launch, market
                    and distribute these products in the various market
                    segments.
                  </p>

                  <p>
                    <span className="textRed">I</span>nnovative{" "}
                    <span className="textRed">C</span>entre for{" "}
                    <span className="textRed">A</span>pplied{" "}
                    <span className="textRed">N</span>ano Technology is one of
                    the first organizations to come up with indigenously
                    developed nanotechnology enabled products.{" "}
                    <span className="textRed">I-CanNano</span> is the brain
                    child of{" "}
                    <strong className="textRed">Dr. Arup Chatterjee</strong> who
                    has been inspired & mentored on this project by{" "}
                    <strong className="textRed">
                      (Lt) Dr. APJ Abdul Kalam
                    </strong>
                    . Dr. Arup Chatterjee, M.Sc, M.Tech & Ph.D, is highly
                    talented scientist with applied technological bent in mind.
                    He is committed towards the application of Nanotechnology
                    for the common man and achieving it through innovation. The
                    vision of Dr. Kalam “Affordable nanotechnology for common
                    man” is well adopted to achieve the goals of the
                    organization. Dr. Arup Chatterjee’s diverse interest in wide
                    application has led to the development of many novel and
                    high performance products. With strong scientific capability
                    & determination, he has crossed many barriers of technology
                    development in his decades of long research on
                    Nanotechnology to arrive at successful products and
                    solutions.
                  </p>

                  <p>
                    Almost 25+ dedicated scientists have worked for 30 years
                    relentlessly in research on nanotechnology base for various
                    related products under the brand{" "}
                    <span className="textRed">I-CanNano</span> and have
                    developed robust process technology for industrial scale
                    manufacturing of Nano-materials that are tuneable in size &
                    structure, scalable and highly pure in terms of quality.{" "}
                  </p>
                </p>
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <div className="details">
                <h5
                  style={{ color: "white", marginBottom: "25px" }}
                  dangerouslySetInnerHTML={{
                    __html: whyUsObj.sectionOne.title,
                  }}
                />
                <ul>
                  <li>
                    {whyUsObj.sectionOne.features.map((item, key) => (
                      <p key={key}>
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={itemPopover(item.icon)}
                        >
                          <span>{item.title}</span>
                        </OverlayTrigger>
                      </p>
                    ))}
                  </li>
                </ul>
              </div>
              <div className="details" style={{ marginTop: "30px" }}>
                <h5
                  style={{ color: "white", marginBottom: "25px" }}
                  dangerouslySetInnerHTML={{
                    __html: whyUsObj.sectionTwo.title,
                  }}
                />
                <ul>
                  <li>
                    {whyUsObj.sectionTwo.features.map((item, key) => (
                      <p key={key}>
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={itemPopover(item.icon)}
                        >
                          <span>{item.title}</span>
                        </OverlayTrigger>
                      </p>
                    ))}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 order-1">
              <div className="content">
                <h4>Our Vision</h4>
                <p>
                  We want to be the most customer-centric & agile company which
                  introduces innovative products & solutions and creates a
                  difference in people’s life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
