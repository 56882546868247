import React, { Fragment } from "react";
import About from "./About";
import About2 from "./About2";
import About3 from "./About3";
import Banner from "./Banner";
import Whyus from "./Whyus";
import DataStore from "../../../data/DataStore";

const HomeContent = ({ history }) => {
  return (
    <Fragment>
      <Banner />
      <Whyus />
      <About3 data={DataStore} history={history} />
      <About />
      <About2 />
    </Fragment>
  );
};

export default HomeContent;
