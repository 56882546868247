import React, { useState, useEffect } from "react";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Content from "../sections/shopdetail/Content";
import { firestore } from "../Firebase";

const ProductDetail = () => {
  const [data, setData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [breadcrumb, setBreadcrumb] = useState({
    breadcrumbimg: "",
    type: "",
    title: "",
  });

  const cId = new URLSearchParams(window.location.search).get("cId");
  const prodId = new URLSearchParams(window.location.search).get("productId");
  const catId = new URLSearchParams(window.location.search).get("categoryId");
  useEffect(() => {
    if (catId) {
      firestore
        .collection("products")
        .doc(prodId)
        .get()
        .then((res) => {
          setCategoryId(catId);
          setData(res.data());
        });
    } else if (cId) {
      firestore
        .collection("products")
        .doc(prodId)
        .get()
        .then((res) => {
          setCategoryId(cId);
          setData(res.data());
        });
    }
  }, [prodId, cId, catId]);

  useEffect(() => {
    if (categoryId) {
      firestore
        .collection("categories")
        .doc(categoryId)
        .get()
        .then((res) => {
          setBreadcrumb({
            breadcrumbimg: res.data().img,
            type: res.data().category,
            title: data.title,
          });
        });
    }
  }, [categoryId, data]);

  return (
    <>
      {breadcrumb.title ? (
        <Breadcrumbs breadcrumb={breadcrumb} test="test" />
      ) : null}
      {data ? <Content data={data} prodId={prodId} cId={cId} /> : null}
    </>
  );
};

export default ProductDetail;
