import React from "react";
import { Link } from "react-router-dom";

import ctaimg from "../../../assets/img/lines/16.png";

export default () => {
  return (
    <section className="cta-aection">
      <div className="container">
        <div
          className="cta-wrap bg-img-c"
          style={{ backgroundImage: "url(" + ctaimg + ")" }}
        >
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="cta-content text-center">
                <div className="section-title both-border mb-30">
                  <span className="title-tag">Get in Touch</span>
                  <h2 className="title">
                    Make a Difference. Get in touch with us today.
                  </h2>
                </div>
                <Link to="/contact" className="main-btn main-btn-3">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
