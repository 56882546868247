import React from "react";
import { Link } from "react-router-dom";

import illustration from "../../../assets/img/home-sec-2-1.jpg";

export default () => {
  return (
    <section className="about-section about-illustration-img ">
      <div className="container">
        <div className="illustration-img">
          <img src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mt-5">
                <span className="title-tag">About Us</span>
              </div>
              <p style={{ marginBottom: "20px" }}>
                Team of enthusiastic professionals with great team spirit –
                That’s WE at Super Marketing. Our team from diverse proven
                background comes together to take Nano Technology to greater
                heights.
              </p>

              <p className="mb-25">
                It is our pride that I-CanNano, pioneers in Nano technology, has
                collaborated with Super Marketing for their range of outstanding
                products. Super Marketing will launch, market and distribute
                these products in the various market segments.
              </p>
              <div className="section-title left-border mb-20">
                <span className="title-tag">Our Vision</span>
              </div>
              <p style={{ marginBottom: "20px" }}>
                We want to be the most customer-centric & agile company which
                introduces innovative products & solutions and creates a
                difference in people’s life.
              </p>
              <Link to="/about" className="main-btn" style={{ float: "right" }}>
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
