import React, { useState, useEffect } from "react";

import img1 from "../../../assets/img/feature/01.jpg";
import ProductItem from "./ProductItem";

const ProductCategoryComponent = ({ data }) => {
  const [prodData, setProdData] = useState([]);

  useEffect(() => {
    if (data) {
      setProdData(data);
    }
  }, [data]);

  return (
    <section className="feature-section section-gap grey-bg">
      <div className="container">
        <div className="section-title text-center both-border mb-50">
          <span className="title-tag"> Products</span>
        </div>
        {/* Feature boxes */}
        <div className="feature-boxes row justify-content-center">
          {prodData.map((item, key) => (
            <ProductItem item={item} img={img1} key={key} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductCategoryComponent;
