import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Content from "../sections/nanotech/Content";

export default () => {
  return (
    <Fragment>
      <MetaTags>
        <title>NanoTechnology | Super Marketing</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      <Breadcrumbs
        breadcrumb={{
          breadcrumbimg: "bg5.jpg",
          type: "About",
          title: "NanoTechnology",
        }}
      />
      <Content />
      <Footer />
    </Fragment>
  );
};
