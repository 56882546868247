import React, { useState, useEffect } from "react";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Content from "../sections/category/Features";
import { firestore } from "../Firebase";

const ProductCategory = () => {
  const [prodData, setProdData] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState({});
  const catId = new URLSearchParams(window.location.search).get("categoryId");

  useEffect(() => {
    if (catId) {
      firestore
        .collection("categories")
        .doc(catId)
        .get()
        .then((res) => {
          setBreadcrumb({
            breadcrumbimg: res.data().img,
            type: "Product Categories",
            title: res.data().category,
            id: res.id,
          });
        });

      firestore
        .collection("products")
        .where("categoryId", "array-contains", catId)
        .orderBy("order")
        .get()
        .then((res) => {
          let prods = [];
          res.docs.forEach((doc) => {
            let prod = doc.data();
            prod["id"] = doc.id;
            prods = [...prods, prod];
          });
          setProdData(prods);
        });
    }
  }, [catId]);

  return (
    <>
      <Breadcrumbs breadcrumb={breadcrumb} />
      <Content data={prodData} />
    </>
  );
};

export default ProductCategory;
